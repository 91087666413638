import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useSelector } from "react-redux";
import { urls } from "../constants/urls";

function useGenerateToken() {
  const config = useSelector((state) => state);
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);
  useEffect(() => {
    const sha256 = (input) => {
      return CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex);
    };
    const fetchToken = async () => {
      try {
        const sellerId = config.digIds.sellerId;
        const timestamp = Math.floor(Date.now() / 1000);
        const apiKey = "2022BDC39F7E4CEC8B4ADD1E01D25566";

        const sign = sha256(apiKey + timestamp);

        const requestData = {
          seller_id: sellerId,
          timestamp: timestamp,
          sign: sign,
        };

        const response = await axios.post(
          `${urls.digisellerURL}/apilogin`,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (response.data.retval === 0) {
          setToken(response.data.token);
        } else {
          setError(response.data.desc);
        }
      } catch (error) {
        setError("Помилка під час виконання запиту");
      }
    };

    fetchToken();
  }, [config.digIds.sellerId]);

  return { token, error };
}

export { useGenerateToken };
