import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MainPage } from "./blocks/MainPage/MainPage";

import "./sass/main.scss";

import { AboutPage } from "./blocks/AboutPage/AboutPage";
import { PolicyPage } from "./blocks/PolicyPage/PolicyPage";
import { CatalogPage } from "./blocks/CatalogPage/CatalogPage";
import { ProdCardPage } from "./blocks/ProdCardPage/ProdCardPage";
import { CartPage } from "./blocks/CartPage/CartPage";
import { SearchPage } from "./blocks/SearchPage/SearchPage";
import { InfoPage } from "./blocks/InfoPage/InfoPage";
import { OrderDetailsPage } from "./blocks/OrderDetailsPage/OrderDetailsPage";

import { useEffect } from "react";
import { getSearchResults } from "./Hooks/GetFunctions";
import { useDispatch, useSelector } from "react-redux";

function App() {
  //make hook later
  const config = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!window.searchResultId) {
      window.searchResultId = 0;
    }

    const thisSearchId = window.searchResultId + 1;
    window.searchResultId = thisSearchId;

    getSearchResults(config).then((data) => {
      if (thisSearchId === window.searchResultId) {
        dispatch({ type: "CHANGE_SEARCH_RESULTS", payload: data });
      }
    });
  }, [config.searchTxt, config.lang, config.currency]);

  //currency
  useEffect(() => {
    window.localStorage.setItem(
      "BroGamesCurrency",
      JSON.stringify(config.currency)
    );
  }, [config.currency]);

  //end make hook later

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/:lang/" element={<MainPage />} />
          <Route path="/:lang/about" element={<AboutPage />} />
          <Route path="/:lang/policy" element={<PolicyPage />} />
          {/**/}
          <Route
            path="/:lang/catalog"
            element={<CatalogPage productType="all" />}
          />
          {/*mb remake for /:category ...*/}
          <Route
            path="/:lang/catalog/accounts"
            element={<CatalogPage productType="accounts" />}
          />
          <Route
            path="/:lang/catalog/keys"
            element={<CatalogPage productType="keys" />}
          />
          <Route
            path="/:lang/catalog/top-up"
            element={<CatalogPage productType={"topUp"} />}
          />
          <Route
            path="/:lang/catalog/currency"
            element={<CatalogPage productType="currency" />}
          />
          <Route
            path="/:lang/catalog/accounts/:subcategory"
            element={<CatalogPage productType="accounts" />}
          />
          <Route
            path="/:lang/catalog/keys/:subcategory"
            element={<CatalogPage productType="keys" />}
          />
          <Route
            path="/:lang/catalog/top-up/:subcategory"
            element={<CatalogPage productType={"topUp"} />}
          />
          <Route
            path="/:lang/catalog/currency/:subcategory"
            element={<CatalogPage productType="currency" />}
          />
          {/*working a little*/}
          <Route path="/:lang/prod/:id" element={<ProdCardPage />} />
          <Route path="/:lang/info/:id" element={<InfoPage />} />
          <Route path="/:lang/cart" element={<CartPage />} />
          <Route path="/:lang/order-details" element={<OrderDetailsPage />} />
          <Route path="/:lang/search" element={<SearchPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
