import { useState, useEffect } from "react";
import axios from "axios";

function useCheckPayment(uniqueCode, token) {
  const [paymentData, setPaymentData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkPayment = async () => {
      try {
        const apiUrl = `https://api.digiseller.ru/api/purchases/unique-code/${uniqueCode}?token=${token}`;

        const response = await axios.get(apiUrl, {
          headers: {
            Accept: "application/json",
          },
        });

        console.log(response, "response");
        if (response.data.retval === 0) {
          setPaymentData(response.data);
        } else {
          setError(response.data.retdesc);
        }
      } catch (error) {
        setError("Помилка під час виконання запиту");
      }
    };

    if (uniqueCode && token) {
      checkPayment();
    }
  }, [uniqueCode, token]);
  return { paymentData, error };
}

export { useCheckPayment };
