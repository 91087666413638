import PreOrderBg from "../../img/PreOrder.png";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import React, {useEffect, useState} from "react";
import {ChevronLeft, ChevronRight} from "../../SvgSpriptes";
import {useLanguage} from "../../Hooks/UseLang";
import {ProdCard} from "../Catalog/ProdCard";
import {getCurrencySymb, getProducts} from "../../Hooks/GetFunctions";
import {useSelector} from "react-redux";


export const PreOrder = (props) => {
    const products = [
        {
            agency_id: 0,
            base_currency: "WMR",
            base_price: 599,
            cntImg: 1,
            collection: "pins",
            currency: "RUR",
            has_discount: 1,
            id: 3828572,
            id_present: 0,
            info: "<delivery>❤️ CS:GO 2 + Prime Статус + Прогресс ❤️ Гарантия ⭐ + Игры<br><br>◻️ Не упустите возможность <br>, пока он все ещё в продаже у Bro_Gamers!<br>◻️ Cкины, дополнительные игры, без лимита (можно добавлять друзей), полная игра (Прайм статус)<br><br>Получите преимущество в CS 2  с аккаунтом, имеющим Prime статус и прогресс! Этот аккаунт открывает доступ к уникальным привилегиям, а также предоставляет дополнительные игры в придачу",
            is_available: 1,
            label: "",
            name: "CS:GO 2 ✔️ Prime Статус ✔️ Прогресс ✔️ + Игры",
            partner_comiss: 5,
            price: 599,
            price_eur: 5.67,
            price_rub: 599,
            price_uah: 256.76,
            price_usd: 6.23
        },
        {
            agency_id: 0,
            base_currency: "WMR",
            base_price: 449,
            cntImg: 3,
            collection: "pins",
            currency: "RUR",
            has_discount: 1,
            id: 3828573,
            id_present: 0,
            info: "❤️ DayZ Steam ❤️ Онлайн ✔️ Region Free ✔️ Навсегда<br><br>◻️ Не упустите возможность , пока он все ещё в продаже у Bro_Gamers!<br>◻️ Cкины, дополнительные игры, без лимита (можно добавлять друзей), полная игра<br><br>Отправляйтесь в опасное выживание в мире зомби с аккаунтом DayZ Standalone на платформе Steam! Этот аккаунт позволит вам наслаждаться игрой онлайн и предоставит дополнительные игры в подарок",
            is_available: 1,
            label: "",
            name: "DayZ Standalone + Livonia✔️ Онлайн ✔️ + Игры ✔️ Дейз",
            partner_comiss: 0,
            price: 449,
            price_eur: 4.25,
            price_rub: 449,
            price_uah: 192.46,
            price_usd: 4.67
        },
        {
            agency_id: 0,
            base_currency: "WMR",
            base_price: 550,
            cntImg: 7,
            collection: "pins",
            currency: "RUR",
            has_discount: 1,
            id: 3828574,
            id_present: 0,
            info: "❤️ RUST Steam ❤️ Онлайн ✔️ Region Free ✔️ <br><br>◻️ Не упустите возможность , пока он все ещё в продаже у Bro_Gamers!<br>◻️ Cкины, дополнительные игры, без лимита (можно добавлять друзей), полная игра<br><br>Ощутите истинный дух выживания с аккаунтом RUST на платформе Steam! Этот аккаунт позволит вам наслаждаться игрой онлайн и получить дополнительные игры в придачу. Выживайте в суровом мире, где природа и другие игроки - ваши главные враги",
            is_available: 1,
            label: "",
            name: "RUST Steam ✔️ Онлайн ✔️ +Игры",
            partner_comiss: 0,
            price: 550,
            price_eur: 5.2,
            price_rub: 550,
            price_uah: 235.76,
            price_usd: 5.72
        }
    ];

    const config = useSelector((state) => state);
    const currentCurrency = useSelector((state) => state.currency)

    const price = (product) => {
        switch (currentCurrency) {
            case "RUB":
                return product.price_rub;
            case "RUR":
                return product.price_rub;
            case "USD":
                return product.price_usd;
            case "UAH":
                return product.price_uah;
            default:
                return product.price;
        }
    };

    useEffect(() => {
        /* getProducts(config, 1, config.digIds.preOrder, 100).then((data) => {
             if (data && data.product) {
                 setProducts([...data.product]);
             }
         });*/
    }, [config.lang, config.currency, config]);

    //
    const [slider, setSlider] = useState();
    const lang = useLanguage().PreOrder;

    if (products.length > 0) {
        return (
            <section className="sPreOrder section">
                <div className="container">
                    <div className="sPreOrder__box">
                        <div className="sPreOrder__bg">
                            <img
                                loading="lazy"
                                src={PreOrderBg}
                                alt="order background"
                                width={"auto"}
                                height={"auto"}
                            />
                        </div>
                        <div className="section-title">
                            <h2>{lang.title}</h2>
                        </div>
                        <Swiper
                            modules={[Pagination]}
                            breakpoints={{
                                0: {
                                    spaceBetween: 16,
                                },
                                576: {
                                    spaceBetween: 32,
                                },
                            }}
                            slidesPerView={"auto"}
                            className={"sPreOrder__slider"}
                            onSwiper={setSlider}
                            pagination={false}>
                            {products.map((product, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <ProdCard
                                            product={product}
                                            itemId={product.id}
                                            name={product.name}
                                            isAvailable={product.is_available}
                                            price={`${Math.ceil(price(product))} ${getCurrencySymb(
                                                config.currency
                                            )}`}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                            {/*<TransparentChevrons slider={slider}/>*/}
                        </Swiper>
                    </div>
                </div>
            </section>
        );
    }
};

export const TransparentChevrons = (props) => {
    let {slider, displayClasses} = props;

    return (
        <>
            <div
                className={`swiper-tp-btn ${
                    displayClasses ? displayClasses : "d-none d-md-flex"
                } prev`}
                onClick={() => {
                    slider.slidePrev();
                }}>
                <ChevronLeft/>
            </div>
            <div
                className={`swiper-tp-btn ${
                    displayClasses ? displayClasses : "d-none d-md-flex"
                } next`}
                onClick={() => {
                    slider.slideNext();
                }}>
                <ChevronRight/>
            </div>
        </>
    );
};
