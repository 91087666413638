import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { Cart } from "./Cart";
import { Seo } from "../Seo/Seo";
import { useTrackLang } from "../../Hooks/useTrackLang";
import { useLanguage } from "../../Hooks/UseLang";

export const CartPage = (props) => {
  const trackLang = useTrackLang();
  const lang = useLanguage().Cart;

  return (
    <>
      <Seo
        title={`BroGamers | ${lang.title}`}
        description={lang.mainDescription}
        type="webapp"
        name="BroGamers"
        href={"/cart"}
      />
      <h1 className="sr-only">{lang.h1Seo}</h1>
      <div className="main-wrapper">
        <Header />
        <main>
          <Cart />
        </main>
        <Footer />
      </div>
    </>
  );
};
