import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams, Navigate } from "react-router-dom";

import { useGenerateToken } from "../../Hooks/useGenerateToken";
import { useCheckPayment } from "../../Hooks/useCheckPayment";
import { useLanguage } from "../../Hooks/UseLang";

import { urls } from "../../constants/urls";

const SECRET_TOKEN = "zjrAdCpceCjqSQJewtvyhAjZhuEuTJdcDYzCTK55xndAtw8SRY";

const OrderDetails = () => {
  const lang = useLanguage().OrderDetailsPage;

  const [interhubData, setInterhubData] = useState(null);
  const [conversion, setConversion] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);

  const [searchParams] = useSearchParams();
  const uniqueCode = searchParams.get("uniquecode");

  const { token } = useGenerateToken();
  const { paymentData } = useCheckPayment(uniqueCode, token);

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: "GET",
        url: `${urls.currencyConversionURL}/convert`,
        params: {
          from:
            paymentData.type_curr === "WMR"
              ? "RUB"
              : paymentData.type_curr === "WMZ"
              ? "USD"
              : "UAH",
          to: "UZS",
          amount: paymentData.profit,
        },
        headers: {
          "X-RapidAPI-Key":
            "d759b44e4dmsh8f43df5958a46f5p172a49jsncd784959e778",
          "X-RapidAPI-Host":
            "currency-conversion-and-exchange-rates.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.request(options);
        setConversion(response.data);
        console.log(response.data, "conversion");
      } catch (error) {
        console.error(error);
      }
    };
    if (paymentData && uniqueCode) fetchData();
  }, [paymentData, uniqueCode]);

  useEffect(() => {
    const fetchData = async () => {
      const merchantId = "92";
      const account = paymentData.options[0].value;
      const amount = Math.trunc(conversion.result * 100) / 100;
      const agentTransactionId = "prod_" + uniqueCode;
      const apiUrl = `${urls.interhubURL}/payment/check`;

      try {
        const headers = {
          "Content-Type": "application/json",
          token: SECRET_TOKEN,
        };

        const requestBody = {
          method: "check",
          amount,
          service_id: merchantId,
          agent_transaction_id: agentTransactionId,
          account,
        };

        const response = await axios.post(apiUrl, requestBody, { headers });

        setInterhubData(response.data);
        console.log(response.data);
        console.log("interhubData");
      } catch (error) {
        console.error(error);
      }
    };
    if (conversion) fetchData();
  }, [conversion]);

  useEffect(() => {
    const makePayment = async () => {
      const transactionId = interhubData.transaction_id;
      try {
        let apiUrl = "";
        if (paymentStatus && !paymentStatus.success) {
          apiUrl = `${urls.interhubURL}/payment/pay`;
          console.log("pay");
        } else {
          console.log("pay_check");
          apiUrl = `${urls.interhubURL}/payment/check_status`;
        }

        const headers = {
          "Content-Type": "application/json",
          token: SECRET_TOKEN,
        };

        const requestBody = {
          transaction_id: transactionId,
        };

        const response = await axios.post(apiUrl, requestBody, { headers });

        console.log(response.data, "payment useEffect");
        setPaymentStatus(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (interhubData) makePayment();
  }, [interhubData, paymentStatus?.success]);

  if (!uniqueCode) return <Navigate to="/" />;

  return (
    <section className="section sOrder">
      <div className="container">
        <div className="sOrder__wrapper">
          <h2 className="sOrder__title">
            {lang.subTitle}
            <span className="sOrder__descr">{lang.descr}</span>
          </h2>
          <div className="sOrder__inner">
            <div className="sOrder__bill-wrapper">
              <h3 className="sOrder__bill-title">{lang.orderTitle}</h3>
              <ul className="sOrder__bill-items">
                <li className="sOrder__bill-item">
                  <span>{lang.orderNumber}</span>
                  <span>
                    {(paymentData && paymentData.inv) || "Loading..."}
                  </span>
                </li>
                <li className="sOrder__bill-item">
                  <span>{lang.orderDate}</span>
                  <span>
                    {(paymentData && paymentData.date_pay) || "Loading..."}
                  </span>
                </li>
                <li className="sOrder__bill-item">
                  <span>{lang.specifiedLogin}</span>
                  <span>
                    {(paymentData && paymentData.options[0].value) ||
                      "Loading..."}
                  </span>
                </li>
                <li className="sOrder__bill-item">
                  <span>{lang.orderAmount}</span>
                  <span>
                    {(paymentData &&
                      paymentData.amount + " " + paymentData.type_curr) ||
                      "Loading..."}
                  </span>
                </li>
                <li className="sOrder__bill-item">
                  <span>{lang.orderStatus}</span>
                  {paymentStatus ? (
                    <span
                      style={{
                        color:
                          paymentStatus.status === 0
                            ? "#75b022"
                            : paymentStatus.status === 1
                            ? "#ff9700"
                            : "#f34336",
                        fontWeight: "bold",
                      }}>
                      {paymentStatus.status === 0
                        ? lang.orderStatusDone
                        : paymentStatus.status === -108
                        ? lang.orderStatusInProcessing
                        : lang.orderStatusError}
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "#ff9700",
                        fontWeight: "bold",
                      }}>
                      {lang.orderStatusInProcessing}
                    </span>
                  )}
                </li>
              </ul>
            </div>
            <div className="sOrder__info">
              <p>{lang.infoTitle}</p>
              <p>{lang.infoSubtitle}</p>
              <ul className="sOrder__info-items">
                {lang.statusInfo.map((item) => (
                  <li className="sOrder__info-item" key={item.title}>
                    {item.status && (
                      <span style={{ color: `${item.color}` }}>
                        {item.status}
                      </span>
                    )}{" "}
                    {item.title}
                  </li>
                ))}
              </ul>
              <p>{lang.infoDescr}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { OrderDetails };
