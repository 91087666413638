import React from "react";

import { Seo } from "../Seo/Seo";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { OrderDetails } from "../OrderDetails/OrderDetails";

import { useLanguage } from "../../Hooks/UseLang";
import { useTrackLang } from "../../Hooks/useTrackLang";

const OrderDetailsPage = () => {
  const lang = useLanguage().OrderDetailsPage;
  const trackLang = useTrackLang();
  return (
    <>
      <Seo
        title={`BroGamers | ${lang.mainTitle}`}
        description={lang.mainDescription}
        type="webapp"
        name="BroGamers"
        href={"lang:/order-details"}
      />
      <h1 className="sr-only">{lang.h1Seo}</h1>
      <Header />
      <main>
        <OrderDetails />
      </main>
      <Footer />
    </>
  );
};

export { OrderDetailsPage };
