import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { ProdCardHeaderBlock } from "../ProdCardHeaderBlock/ProdCardHeaderBlock";
import { GameInfo } from "../GameInfo/GameInfo";
import { Recent } from "../Recent/Recent";
import { Review } from "../Review/Review";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getItemChars } from "../../Hooks/GetFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { AlsoLikeThis } from "../Recent/AlsoLikeThis";
import { useTrackRecent } from "../../Hooks/useTrackRecent";
import { Seo } from "../Seo/Seo";
import { useLanguage } from "../../Hooks/UseLang";
import { useTrackLang } from "../../Hooks/useTrackLang";

export const ProdCardPage = (props) => {
  const trackLang = useTrackLang();
  const lang = useLanguage().ProdCardPage;
  const params = useParams();
  const navigate = useNavigate();
  const recentIdList = useTrackRecent(params.id);
  const config = useSelector((state) => state);

  const [itemChars, setItemChars] = useState();

  useEffect(() => {
    getItemChars(config, params.id).then((data) => {
      setItemChars(data);
      //console.log(data);
    });
  }, [config.lang, config.currency, navigate]);

  return (
    <>
      {itemChars && (
        <Seo
          title={
            lang.title.buy +
            " " +
            itemChars?.product?.name +
            " " +
            lang.title.cheap
          }
          description={`${lang.description.buy} ${itemChars?.product?.name} ${
            lang.description.cheap
          } ${itemChars?.product?.price + itemChars?.product?.currency} ${
            lang.description.safe
          }`}
          type="webapp"
          name="BroGamers"
          href={`/prod/${itemChars?.product?.id}`}
        />
      )}
      <h1 className="sr-only">{lang.h1Seo}</h1>
      <div className="main-wrapper">
        <Header />
        <main>
          {itemChars && (
            <>
              <ProdCardHeaderBlock
                product={itemChars.product}
                videoArr={itemChars.product.preview_videos}
                imgArr={itemChars.product.preview_imgs}
              />
              <GameInfo
                info={itemChars.product.info}
                addInfo={itemChars.product.add_info}
                id={params.id}
              />
              <AlsoLikeThis itemId={params.id} />
              <Review itemId={params.id} />
              {recentIdList.length > 4 && <Recent itemsList={recentIdList} />}
            </>
          )}
        </main>
        <Footer />
      </div>
    </>
  );
};
